import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function GalleryComponent({ images, text }) {
  const items = images.map((url, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "0 10px", // Margin between images
      }}
    >
      <img
        src={url}
        alt={`Image ${index + 1}`}
        className="gallery-img"
        
      />
    </div>
  ));

  
    const openGoogleMaps = () => {
      window.open(text, "_blank");
    };

  return (
    <div
    className="gallery-container"
      
    >
      <h2 className="galleryTitle" >Venue Gallery</h2> {/* Title */}
      <h4 className="gallerySubTitle">Explore the stunning venue where the Aquaman race will take place. Browse through images showcasing the race village and its scenic surroundings</h4> {/* Title */}
      <br/>
      <AliceCarousel
        mouseTracking
        items={items}
        infinite={true}
        autoPlay={true}
        autoPlayInterval={3000} // Adjust the interval (3 seconds here)
        disableButtonsControls // Disables default arrows
        dotsDisabled={false} // Enables dots
        responsive={{
          0: { items: 1 }, // Display one image at a time across all screens
        }}
      />

           <button onClick={() => openGoogleMaps()} className="locButton">
           Explore the Village
    </button>
    </div>
  );
}

export default GalleryComponent;
