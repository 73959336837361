import React, {useState} from "react";
import shabab from "../images/shabab.svg";
import dubaicouncil from "../images/dubaicouncil.svg";
import somabay from "../images/somabay.svg";
import cib from "../images/cib.svg";
import cocacola from "../images/cocacola.svg";
import arena from "../images/arena.svg";
import PartnerCarousel from "./PartnersCarousel";
import Carousel from "react-multi-carousel";
// import "./partners.scss"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';



function Partners() {
  const [settings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: window.innerWidth < 420 ?1 : window.innerWidth < 720 ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    swipeToSlide: true,
    nextArrow: <></>,
    prevArrow: <></>
  });

  const partners = [
    { id: 1, image: shabab },
    { id: 2, image: dubaicouncil },
    { id: 3, image: somabay },
    { id: 4, image: cib },
    { id: 5, image: "https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/backgrounds/Allianz-White-final.svg" },
    {id: 6, image: "https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/races/somabay/partners/Valu%20White-01.svg"},
    { id: 7, image: arena },
  ];

  return (
    <div>
      {true && (
        <div
          style={{
            width: "85vw",
            height: "84px",
            backgroundColor: "transparent",
            // position: "absolute",
            // left: "50%",
            bottom: window.innerWidth < 720 ? "15vh" : "20vh",
            //  transform: "translateX(-50%)",
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            padding: "32px",
          }}
        >


<Slider {...settings}>
      {partners.map((partner) => (
        <>
        {console.log(partner.image)}
          <img style={{ display: "block",
                  height: "100px",
                  // margin: "0 30px",
                  width: "140px",
                  objectFit: "fill"}} src={partner.image} alt={`Partner ${partner.id}`} />
        </>

      ))}
    </Slider>

          {/* {partners.map((partner) => (
            <img
              key={partner.id}
              src={partner.image}
              alt={`Partner ${partner.id}`}
            />
          ))} */}
        </div>
      )}
    </div>
  );
}

export default Partners;
