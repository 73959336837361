import React, {useState} from "react";
import CollapsibleSection from "./collapsibleSection.js"
import CountrySelectorDialog from "../component/countrySelectorDialog.js";


function RegistrationComponent({topTable, includesArray, pdf, id, isActive, activeUrl, isLocal}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = (country) => {
    console.log('Selected country:', country);
    handleCloseModal();
  };

    const listItems = [
        'Race Tattoo', 'Finishers T-Shirt', 'Aid Stations',
        'Timing Chip', 'Finishers Medal', 'Volunteer Support',
        'Race Swim Cap', 'Timed Race Results',
        'Race Kit', 'Medical Support', 'An experience you’ll never forget', 'Post-event recovery area with food, drink & physiotherapy'
      ];

  return (
    <div style={window.innerWidth > 700 ? {paddingTop: "64px"}: {paddingTop: "16px"}}>
      <div className="registration-firstPart">
        <h3>Become The Next Aquaman</h3>
        <br/>
        <p>
          Embark on your swimming journey with us, register today, and choose
          the race that suits you best!
        </p>
        <br/>
        {isActive ? <button onClick={() => {
           handleOpenModal()
        }}>Register Now</button> : <button onClick={() => {
       }}>Registration Opening Soon</button> }
      </div>
      <div className="image-container">
      <img className="responsive-image" style={{marginTop: "2em"}} src={topTable}/>
      </div>
      <p style={{fontSize:"13px", lineHeight: "normal", marginTop: "1em", color: "#112348"}}>Please note that the price for aquateam placed is per person in the team of 3. {isLocal && "For payment processing Egyptians can pay in EGP"}</p>
      <div>
      <div>
      <CollapsibleSection title="Registration Includes">
      <ul className="list-grid">
      {listItems.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
      </CollapsibleSection>
      <CollapsibleSection title="Rules and Regulations">
        <div>
        <p style={{fontSize: "16px", color:"#112348", margin:"16px 0"}}>If you'd like to review our rules and regulations, simply click “Download PDF” below.</p>
        <button className="button" onClick={() => window.open(pdf, "_blank")}>Download PDF</button>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title="Volunteer Information">
      <div style={{color:"#112348", letterSpacing:"normal"}}>
          <p>Volunteers form the backbone of our events, and we're constantly seeking enthusiastic, driven individuals to join our team and participate. As a volunteer, you'll be at the heart of the action, with a range of roles to suit your preferences and availability. Whether it's assisting with athlete registration or guiding participants at the finish line, there's a rewarding role waiting for you!</p>
         <br/>
         <p>We deeply appreciate every single one of our volunteers, and as a token of our gratitude for your dedication and hard work, we offer each volunteer:</p>
         <br/>
          <ul style={{listStyleType: "disc", marginLeft: "12px"}}>
            <li>An event volunteer t-shirt</li>
            <li>All day meals</li>
            <li>Accommodations</li>
            <li>Transportation from and to the event</li>        
         </ul>
        <br/>
        <p>This event promises to be truly spectacular, and we invite our community to come together, show support, and join in the excitement!</p>
        <br/>
        <p>As a volunteer, you might be tasked with various roles, including:</p>
        <br/>
        <ul style={{listStyleType: "disc", marginLeft: "12px"}}>
            <li>Assisting with event registration</li>
            <li>Acting as a course marshal to guide athletes</li>
            <li>Directing athletes at the finish line</li>
            <li>Managing water stations or turnaround points</li>  
            <li>Assisting spectators at pedestrian crossing zones</li>      
         </ul>
         <br/>
          <p>For any 'Individual' wanting to volunteer please register and select your shifts <a href="#">via this link</a>.</p>
          
          {/* Call to action for groups */}
          <p>For any groups wishing to join or who are a part of LOC please email <a href="mailto:communication@aquaman.co">communication@aquaman.co</a> for further information.</p>
        </div>
      </CollapsibleSection>
    </div>
      </div>
      {isModalOpen && <CountrySelectorDialog
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onConfirm={handleConfirm}
        id={id}
        activeUrl={activeUrl}
        isLocal={isLocal} 

      />} 
    </div>
  );
}

export default RegistrationComponent;
