import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "./PaymentSuccess.scss"

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PaymentConfirmation = () => {
  const query = useQuery();
  const [event, setEvent] = useState({ name: '', location: '', dateString: '', image: '' });

  useEffect(() => {
    const eventName = query.get('name');
    const eventLocation = query.get('location');
    const eventDateString = query.get('dateString');
    const eventImage = query.get('image');

    setEvent({ name: eventName, location: eventLocation, dateString: eventDateString, image: eventImage });
  }, [query]);

  return (
    <div>
      <style jsx>{`
        body, h1, h2, h3, p { margin: 0; padding: 0; }
        body { font-family: 'Poppins', sans-serif; }
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        .appbar {
          display: flex;
          align-items: center;
          background-color: #112348;
          padding: 16px 0px;
          color: white;
        }
        .appbar a {
          color: white;
          text-decoration: none;
          margin: 0 10px;
        }
        .logo {
          height: 22px;
          background-size: 80px 58px; 
          margin: 18px 5%;
        }
        .main-image {
          position: relative;
          width: 90%;
          height: 44vh;
          background: url('https://firebasestorage.googleapis.com/v0/b/aquaman-5b7b3.appspot.com/o/Aquaman%20Luxor%2FFirst%20Fold.png?alt=media&token=e820fb92-65f6-4cf9-b060-b6f2383d5011') no-repeat center center;
          background-size: cover;
        }
        .overlay-text {
          overflow: unset;
          white-space: nowrap;
        }
       
        .info {
          margin-bottom: 1em;
        }
        .overlay-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          color: white;
        }
       
      
        .success-image {
          height: 100px;
        }
        .payment-container {
          text-align: center;
          padding: 20px;
        }
       
        .ok-button {
          background-color: #4CAF50;
          border: none;
          color: white;
          padding: 10px 20px;
          cursor: pointer;
          margin-top: 1em;
          border-radius: 5px;
          width: fit-content
        }
        .ok-button:hover {
          background-color: #45a049;
        }
      `}</style>

      {/* <div className="appbar">
        <img className="logo" src="https://makanakboost.s3.eu-central-1.amazonaws.com/mainlogo.svg" alt="Logo" />
      </div> */}
      <div className="container">
        <div className="main-image">
          <div className="overlay-text">
            <h1>{event.name}</h1>
            <p>{event.location}</p>
            <span className="main-date">{event.dateString}</span>
          </div>
        </div>
        <div className="payment-container">
          <div className="payment-header">
            <img src="https://makanakboost.s3.eu-central-1.amazonaws.com/icons/success.svg" alt="Success" className="success-image" />
            <h2>Thank you! Your registration for the event has been confirmed.</h2>
          </div>
          <p className="contact-info">We can't wait to see you on the event day for an incredible adventure!</p>
          <p className="notice">We will get in contact soon, and a follow-up email will be sent.</p>
          <button className="ok-button" onClick={() => window.location.href='https://www.aquaman.co'}>Back to Home Page</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;