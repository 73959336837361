import React, { useState } from "react";
import applyPromo from "../../api/events/applyPromo";
import fullCoverageCheckout from "../../api/events/fullCoverageCheckout";
import { useNavigate } from "react-router-dom";

function CheckoutSummary(props) {
  const navigate = useNavigate();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isRulesAccepted, setIsRulesAccepted] = useState(false);
  const [isWaverAccepted, setIsWaverAccepted] = useState(false);
  const [promo, setPromo] = useState("");
  const [isValu, setIsValu] = useState()
  const [isCreditCard, setIsCreditCard] = useState(true)
  const [oldPrice, setOldPrice] = useState(null);
  const [promoError, setPromoError] = useState(false);
  const [price, setPrice] = useState(props.price) 
  const [iframe, setIframe] = useState(props.iframe)
  const [iframeValu, setIframeValu] = useState(props.iframeValu)
  const [isFullCoverage, setIsFullCoverage] = useState(false)
  const [queryParams, setQueryParams] = useState()
  const handlePromo = () => {
    applyPromo({
      promoCode: promo,
      eventCartId: props.eventCartId,
    })
      .then((res) => {
        setPrice(res.totalPrice); // Assuming setPrice is a prop function to update the price
        setOldPrice(res.pricePreDiscount);
        setIframe(res.iFrame)
        setIframeValu(res.iFrameValu)
        setIsFullCoverage(res.skipPayment)
        setQueryParams( new URLSearchParams({ name: res.event.name, location: res.event.location, dateString: res.event.dateString, image: res.event.successImg }).toString())
        console.log(new URLSearchParams({ name: res.event.name, location: res.event.location, dateString: res.event.dateString, image: res.event.successImg }).toString(),"gsgsg", res.event  )
      })
      .catch((error) => {
        console.log(error)
        setPromoError(true);
      });
  };
  

const handleFullCoverage = () => {
  fullCoverageCheckout({eventCartId: props.eventCartId}).then(response => {
    navigate(`/success?${queryParams}`)
  }).catch(error => console.log(error))
}

  return (
    <div className="checkout-content-right">
      <p className="checkout-content-width" style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px", color: "#112348", alignSelf: "flex-start" }}>
        Payment Summary
      </p>
      <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#112348", alignSelf: "flex-start", marginTop: "48px", marginBottom: "16px" }}>
        Terms and Conditions Acceptance
      </p>
      <div className="checkout-read">
        <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
          <input type="checkbox" checked={isTermsAccepted} onChange={() => setIsTermsAccepted(!isTermsAccepted)} />
          <p style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", whiteSpace: "nowrap" }}>I Accept</p>
        </div>
        <p className="whitespace-dynamic" style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", cursor: "pointer" }}>
          I have read and I accept Aquaman Terms & Conditions
        </p>
      </div>
      <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#112348", alignSelf: "flex-start", marginTop: "48px", marginBottom: "16px" }}>
        Rules and Regulation Acceptance
      </p>
      <div className="checkout-read">
        <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
          <input type="checkbox" checked={isRulesAccepted} onChange={() => setIsRulesAccepted(!isRulesAccepted)} />
          <p style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", whiteSpace: "nowrap" }}>I Accept</p>
        </div>
        <p className="whitespace-dynamic" style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", cursor: "pointer" }} onClick={() => window.open("https://makanakboost.s3.eu-central-1.amazonaws.com/Aquaman_Rules%20and%20Regulations.pdf", "_blank")}>
          I have read and I accept Aquaman Rules & Regulations
        </p>
      </div>
      <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#112348", alignSelf: "flex-start", marginTop: "48px", marginBottom: "16px" }}>
        Waiver
      </p>
      {  console.log(isFullCoverage)
}
      <div className="checkout-read">
        <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
          <input type="checkbox" checked={isWaverAccepted} onChange={() => setIsWaverAccepted(!isWaverAccepted)} />
          <p style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", whiteSpace: "nowrap" }}>I Accept</p>
        </div>
        <p className="whitespace-dynamic exp" style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", cursor: "pointer" }} onClick={() => window.open("https://makanakboost.s3.eu-central-1.amazonaws.com/Waiver.pdf", "_blank")}>
          I have read and accepted the <span style={{ textDecoration: "underline", marginRight: "0" }}>Discharge of Liability Aquaman Somabay 2024</span>
        </p>
      </div>

     

      <div style={{ backgroundColor: "#D69B29", height: "1px", width: "100%", margin: "32px auto" }} />

      <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#112348", alignSelf: "flex-start", marginTop: "28px", marginBottom: "16px" }}>
        Choose your Payment Method
      </p>
      <div className="checkout-read" style={{paddingLeft: "1em"}}>
        <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
        <label className="custom-checkbox">

          <input type="checkbox" checked={isCreditCard} onChange={() =>{ 
            setIsCreditCard(!isCreditCard)
            setIsValu(false)
          }
            } />
             
             <span className="custom-checkmark"></span>
            </label>
          <p style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", whiteSpace: "nowrap", marginLeft: "1em"  }}>Pay with Card</p>
        </div>
        <img className="whitespace-dynamic exp" style={{width: "6em"}} src={"https://www.freepnglogos.com/uploads/visa-card-and-mastercard-logo-png-28.png"}>
          
        </img>
      </div>

      <div className="checkout-read" style={{paddingLeft: "1em"}}>
        <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
        <label className="custom-checkbox">
          <input type="checkbox" checked={isValu} onChange={() => {
            setIsValu(!isValu)
            setIsCreditCard(false)}} />
               
               <span className="custom-checkmark"></span>
            </label>
          <p style={{ color: "#D69B29", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", whiteSpace: "nowrap", marginLeft: "1em" }}>Valu Installments</p>
        </div>
        <img className="whitespace-dynamic exp" style={{width: "5em"}} src={"https://api.mobilaty.com/storage/uploads/valu%202-1693729969.png"}>
          
        </img>
      </div>

      <div className="checkout-promo-cont">
        {console.log(promoError)}
        <input className="checkout-input" style={promoError == true ? { borderColor: "red" } : { borderColor: "#D69B29" }} onChange={(e) => { setPromoError(false); setPromo(e.target.value); }} placeholder="Promo code" />
        <button onClick={handlePromo} style={{ backgroundColor: "#D69B29", borderRadius: "0 6px 6px 0", width: "30%", margin: "0", color: "white", border: "1px solid #D69B29" }}>
          Apply
        </button>
      </div>
    {oldPrice && <div  style={{color: "forestgreen", width: "100%"}}>
        Promo code successfully applied
        </div>}

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: "2em" }}>
        <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "18px", color: "#112348", whiteSpace: "nowrap" }}>Total</p>
        {oldPrice ? (
          <span style={{display: "flex"}}>
            <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "15px", color: "#302348aa", whiteSpace: "nowrap", textDecoration: "line-through", marginRight: "8px" }}>{oldPrice}</p>
            <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "18px", color: "#112348", whiteSpace: "nowrap" }}>{price} USD</p>
          </span>
        ) : (
          <p style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "18px", color: "#112348", whiteSpace: "nowrap" }}>{price} USD</p>
        )}
      </div>

      <button className= {isCreditCard || isValu? "CheckoutBtn" : "CheckoutBtn CheckoutBtnDisable"}  onClick={() => {
        if (!isTermsAccepted || !isRulesAccepted || !isWaverAccepted) {
          alert("Please accept the terms and conditions and rules and regulations.");
          return;
        }else{
          console.log(isFullCoverage)
          if(isFullCoverage){
            handleFullCoverage()
          }else{
            if(isCreditCard || isValu){
              if(isCreditCard){
                window.location.href = iframe;
              }else if(isValu){
                window.location.href = iframeValu
              }
            }
          }
        }
      }}>
        Checkout
      </button>
      <p style={{ fontFamily: "Poppins" }}>NOTE: Payments are made in EGP</p>
    </div>
  );
}

export default CheckoutSummary;
