import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { getAnalytics, logEvent } from "firebase/analytics";

const handleDragStart = (e) => e.preventDefault();

const Gallery = ({ imageUrls, ids, isActives }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [clickStartTime, setClickStartTime] = useState(0); // Track when a click starts
  const analytics = getAnalytics();
  const navigate = useNavigate();

  const MIN_HOLD_TIME = 15000; // Threshold for detecting a hold (in ms)

  const handleMouseDown = () => {
    setClickStartTime(Date.now());
    setIsDragging(false);
  };

  const handleMouseUp = (index) => {
    const clickDuration = Date.now() - clickStartTime;
    if (clickDuration < MIN_HOLD_TIME && !isDragging) {
      // If the click was short and no dragging occurred
      if (isActives[index]) {
        navigate(`/event/${ids[index]}`);
        logEvent(analytics, "Enter_event_Screen");
      }
    }
    setIsDragging(false); // Reset dragging state
  };

  const handleTouchStart = () => {
    setClickStartTime(Date.now());
    setIsDragging(false);
  };

  const handleTouchEnd = (index) => {
    const clickDuration = Date.now() - clickStartTime;
    if (clickDuration < MIN_HOLD_TIME && !isDragging) {
      // If the tap was short and no dragging occurred
      if (isActives[index]) {
        navigate(`/event/${ids[index]}`);
        logEvent(analytics, "Enter_event_Screen");
      }
    }
    setIsDragging(false); // Reset dragging state
  };

  const handleMouseMove = () => setIsDragging(true);
  const handleTouchMove = () => setIsDragging(true);

  // Group image URLs into individual slides
  const items = imageUrls.map((url, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "0 16px", // Spacing between images
      }}
    >
      <img
        src={url}
        alt={`Image ${index + 1}`}
        onDragStart={handleDragStart}
        role="presentation"
        onMouseDown={handleMouseDown}
        onMouseUp={() => handleMouseUp(index)}
        onTouchStart={handleTouchStart}
        onTouchEnd={() => handleTouchEnd(index)}
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        style={{
          width: "621px",
          height: "500px",
          cursor: "pointer",
        }}
      />
    </div>
  ));

  return (
    <div
      style={{
        position: "relative",
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <AliceCarousel
        mouseTracking
        items={items}
        infinite={false} // Prevent looping for better edge-case handling
        disableButtonsControls // Removes navigation arrows
        responsive={{
          0: { items: 1.5 }, // 1 full item + 0.5 item on very small screens
          768: { items: 2.5 }, // Default: 2 full items + 0.5 item on medium screens
          1200: { items: 2.5 }, // Same for larger screens
        }}
        controlsStrategy="responsive"
        autoWidth={false}
        renderDotsItem={() => null} // Hides dots, if needed
      />
    </div>
  );
};

export default Gallery;
