import React, { useState, useEffect } from "react";
import "./MakanakForm.scss";
import fullLogoWhite from "./fullLogoWhite.svg";
import earlyApply from "../api/makanak/earlyApply";
import surveyapi from "../api/makanak/survay";

const MakanakForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [surveySubmit, setSurveySubmit] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });


  useEffect(() => {
    const elementsToHide = document.querySelectorAll(
      ".parallax-footer, .footer-flex, .social-footer-mobiles, .footer-copy-text, .footer"
    );

    // Apply display: none to each element
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });
  },[])

  const [survey, setSurvey] = useState({
    question1: {},
    question2: {},
    question3: {},
    question4: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSurveyChange = (e, question) => {
    const { name, value } = e.target;
    
    setSurvey({
      ...survey,
      [name]: {value:value, question: question},
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    earlyApply(formData)
  };

  const handleSubmitSurvey = (e) => {
    e.preventDefault();
    setSurveySubmit(true);
    let surveyCp = {...survey}
    surveyCp.user = formData
    let body = {
        data: surveyCp,
        tag: "Makanak-Pre",
    }
    surveyapi(body)
  }

  return (
    <div className="makanak-body">
      <div className="MainContainer">
      <img src={fullLogoWhite} alt="Logo" className="logot" />{" "}
      {/* Logo at the top */}
      <div className="containerMakanak">
        {!submitted ? (
          <>
            <div className="explanatory-text">
            <div>
  <strong>Private swimming sessions</strong>
  <p>
    Enjoy personalized one-on-one swimming lessons tailored to your skill level.
  </p>
</div>
<div>
  <strong>Group swimming sessions</strong>
  <p>
    Join group classes to improve your swimming skills alongside others in a fun environment.
  </p>
</div>
<div>
  <strong>Lifeguard booking</strong>
  <p>
    Book professional lifeguards to ensure safety at your pool or event.
  </p>
</div>
<div>
  <strong>Swim essentials store</strong>
  <p>
    Find all the necessary swim gear and accessories to improve your swimming experience.
  </p>
</div>

            </div>

            <form className="formMakanak" onSubmit={handleSubmit}>
              <label className="labelMakanak">
                Name:
                <input
                className="inputMakanak"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label className="labelMakanak">
                Phone Number:
                <input
                className="inputMakanak"
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label className="labelMakanak">
                Email:
                <input
                className="inputMakanak"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <button className="buttonMakanak" type="submit">Submit</button>
            </form>
          </>
        ) : !surveySubmit?  (
          <div className="survey">
            <h5>Please fill out the survey</h5>
            <form className="formMakanak" onSubmit={handleSubmitSurvey}>
              {[{question:"How likely are you to use the app to book a private swimming coach for yourself or a family member?",value: "question1"}, {question:"How likely are you use the app to book sessions in a swimming academy for yourself or a family member?",value: "question2"}, {question:"How likely are you to use the app to book a lifeguard privately or for events?",value: "question3"}, {question: "How likely would you use a store for swimming related products",value: "question4"}].map(
                (item, index) => (
                  <div key={index} className="survey-row">
                    <p>{item.question}</p>
                    <div className="survey-options">
                      <label className="labelMakanak" style={{textAlign:"center"}}>
                        <input
                        className="inputMakanak"
                          type="radio"
                          name={item.value}
                          value="1"
                          checked={survey[item.value].value === "1"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                        <strong>1</strong>
                      </label>
                      <label className="labelMakanak" style={{textAlign:"center"}}>
                        <input
                        className="inputMakanak"
                          type="radio"
                          name={item.value}
                          value="2"
                          checked={survey[item.value].value === "2"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>2</strong>
                      </label>
                      <label className="labelMakanak" style={{textAlign:"center"}}>
                        <input
                        className="inputMakanak"
                          type="radio"
                          name={item.value}
                          value="3"
                          checked={survey[item.value].value === "3"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>3</strong>
                      </label>
                      <label className="labelMakanak" style={{textAlign:"center"}}>
                        <input
                        className="inputMakanak"
                          type="radio"
                          name={item.value}
                          value="4"
                          checked={survey[item.value].value === "4"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>4</strong>
                      </label>
                      <label className="labelMakanak" style={{textAlign:"center"}}>
                        <input
                        className="inputMakanak"
                          type="radio"
                          name={item.value}
                          value="5"
                          checked={survey[item.value].value === "5"}
                          onChange={e => handleSurveyChange(e, item.question)}
                        />{" "}
                            <strong>5</strong>
                      </label>
                    </div>
                  </div>
                )
              )}
                <button className="buttonMakanak" type="submit">Submit</button>
            </form>
          </div>
        ): (
            <div style={{height: "70vh"}}>
                <h4 style={{color:"#033275"}}>Thank you <strong style={{color:"#3385f9"}}>{formData.name}</strong> for your survey</h4>
                <video style={{ width: "100%" }} autoPlay muted playsInline>
  <source src="https://cdn.dribbble.com/users/571236/screenshots/2888472/media/49a51e571776c9de18e7c80b657bd4ee.gif" type="video/mp4" />
  Your browser does not support the video tag.
</video>
                {/* <img style={{width: "100%"}} src={}></img> */}
            </div>
        )}
      </div>
      <div class="social-footer-mobiles-makanak">
        <ul class="list-social">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "80vw",
              margin: "24px auto 8px auto",
            }}
          >
            <div className="insta social-icon" alt="" />
            <div className="facebook social-icon" alt="" />
            <div className="youtube social-icon" alt="" />
            <div className="tiktok social-icon" alt="" />
            <div className="mail social-icon" alt="" />
          </div>
        </ul>
      </div>
      <div class="footer-copy-text-makanak">
        <p>
          Terms and Conditions / Website Terms of Use / Privacy Policy / Cookie
          Preferences © 2024 Makanak
        </p>
      </div>
      </div>
    </div>
  );
};
export default MakanakForm;
