import React, { useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate } from "react-router-dom";


// Ensure to set the app element for accessibility reasons
Modal.setAppElement('#root');

function CountrySelectorDialog({ isOpen, onRequestClose, onConfirm, id, activeUrl, isLocal }) {
  const [country, setCountry] = useState(null);
  const options = countryList().getData();

  const navigate = useNavigate();


  const handleChange = selectedOption => {
    setCountry(selectedOption);
  };

  const handleConfirm = () => {
    if(country != null){
        onConfirm(country);
        onRequestClose(); // Close dialog after confirmation
        console.log(country)
        if(country.value == "EG"){
          if(isLocal){
            navigate(`/event/payment/${id}`)
          }else{
            window.open(activeUrl, '_blank');
          }
        }else{
          window.open(activeUrl, '_blank');
        }
    }else{
        alert("Please select a country")
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,  // Ensure dropdown is on top of other content
      position: 'absolute'  // Allows dropdown to extend outside modal
    }),
    control: (provided) => ({
      ...provided,
      zIndex: 9999
    })
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Country Selector"
      className="Modal"
      overlayClassName="Overlay"
      style={{
        content: {
          overflow: 'visible'  // Allows dropdown to be visible outside the modal
        }
      }}
    >
      <h2>Please Select your nationality to proceed</h2>
      <div className='width-dynamic'>
      <Select
        options={options}
        value={country}
        onChange={handleChange}
        styles={customStyles}
        className="dropdown"
      />
      <div style={{width: "60%", margin: "auto"}}>
        <button style={{backgroundColor: "#D69B29", color: "white"}} onClick={handleConfirm}>Proceed</button>
      </div>
      </div>
    </Modal>
  );
}

export default CountrySelectorDialog;
